<template>
  <dx-util-popup
    ref="materialPurchaseReductionPopupRef"
    width="400px"
    height="auto"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="materialPurchaseReductionPopupShown"
    @hidden="materialPurchaseReductionPopupHidden"
  >
    <div>
      <dx-util-form id="materialItem" ref="materialReductionForm" :form-data="materialReductionForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="materialReductionForm">
        <dx-util-item data-field="decrementAmount" editor-type="dxNumberBox" :label="{text: 'Reduction Quantity'}">
          <dx-util-required-rule message="Quantity is required" />
        </dx-util-item>
      </dx-util-form>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import prepMaterialInventoryService from '@/http/requests/prep-material/prepMaterialInventoryService'

// import { Notify } from '@/@robustshell/utils'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    prepMaterialItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      materialReductionForm: {
        decrementAmount: null,
        prepMaterialId: null,
      },
    }
  },
  computed: {
    materialPurchaseReductionPopup() {
      return this.$refs.materialPurchaseReductionPopupRef.instance
    },
    quantityOptions() {
      return {
        format: '#,##0',
        min: 0,
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.materialReductionForm.prepMaterialId = this.prepMaterialItem.id
        this.materialPurchaseReductionPopup.show()
      },
    },
  },
  methods: {
    materialPurchaseReductionPopupShown() {
      this.popupTitle = `Reduce the inventory of ${this.prepMaterialItem.title} `
    },
    materialPurchaseReductionPopupHidden() {
    // Clear form when popup is closed
      this.materialReductionForm.decrementAmount = null
      this.materialReductionForm.prepMaterialId = null
    },
    closePopup() {
      this.materialPurchaseReductionPopup.hide()
    },
    async handleSubmit() {
      const result = this.$refs.materialReductionForm.instance.validate()
      if (result.isValid) {
        await prepMaterialInventoryService.decrement(this.materialReductionForm)
        this.$emit('emit-form-saved')
        this.closePopup()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
