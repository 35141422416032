import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all prep material inventory
  async fetchAll(pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.prepMaterialInventory.Controller.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getByQuery(filter, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.prepMaterialInventory.Controller.getByQuery(pageable), filter)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // get prep material inventory by id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.prepMaterialInventory.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // get prep material inventory by title
  async fetchByTitle(title) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.prepMaterialInventory.Controller.findByTitle(title))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create prep material inventory
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.prepMaterialInventory.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update prep material inventory
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.prepMaterialInventory.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete prep material inventory
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.prepMaterialInventory.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async adjust(data) {
    const result = await axios.put(endpoints.prepMaterialInventory.Controller.adjust(), data)
    return result.data.body
  },
  async decrement(data) {
    const result = await axios.put(endpoints.prepMaterialInventory.Controller.decrement(), data)
    return result.data.body
  },
  async getChargeSelect() {
    const result = await axios.get(endpoints.prepMaterialInventory.Controller.getChargeSelect())
    return result.data.body
  },

}
